import { Link } from "gatsby";
import * as React from "react";
import { BrushCanvas } from "../components/BrushCanvas";

import * as styles from "../styles/pages/404.module.scss";

// markup
const NotFoundPage = () => {
  return (
    <main className={styles.main}>
      <div className={styles.content}>
        <h1>Page not found</h1>
        <p>
          <Link to="/">
            Go to the home page to find what you're looking for
          </Link>
        </p>
      </div>
      <BrushCanvas />
    </main>
  );
};

export default NotFoundPage;
