import React, { useCallback, useEffect, useRef } from "react";
import { useWindowDimensions } from "../../utils/hooks/windowDimensions";
import { loadImage } from "../../utils/promises";
import { listen } from "../../utils/utils";

import * as styles from "./BrushCanvas.module.scss";

const BrushCanvas = () => {
  const { width, height } = useWindowDimensions();
  const unlistenRef = useRef(() => {});
  const canvasRef = useRef(null);

  // const onCanvasRef = useCallback((ref: null | HTMLCanvasElement) => {
  //   if (ref) {
  //     const WIDTH = window.innerWidth;
  //     const HEIGHT = window.innerHeight;

  //   }
  // }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    let mouseX = -500;
    let mouseY = -500;

    canvas.width = width;
    canvas.height = height;

    const offCanvas = document.createElement("canvas");
    const offCtx = offCanvas.getContext("2d");
    let frameId = 0;

    const u = listen(document, "mousemove", (e) => {
      mouseX = e.clientX;
      mouseY = e.clientY;
    });

    loadImage("/painting.jpeg").then((img) => {
      let hue = 0;
      const update = () => {
        frameId = requestAnimationFrame(update);

        const w = 100;
        const h = (w / img.width) * img.height;

        offCanvas.width = w;
        offCanvas.height = h;
        offCtx.globalCompositeOperation = "source-over";
        offCtx.drawImage(img, 0, 0, w, h);
        offCtx.globalCompositeOperation = "hue";
        offCtx.fillStyle = "hsl(" + hue + ",1%, 50%)"; // sat must be > 0, otherwise won't matter
        offCtx.fillRect(0, 0, w, h);

        ctx.drawImage(offCanvas, mouseX - w / 2, mouseY - h / 2, w, h);
        // ctx.fillRect(mouseX, mouseY, 10, 10);

        hue = (hue + 7) % 360;
      };

      update();
    });

    return () => {
      cancelAnimationFrame(frameId);
      u();
    };
  }, [width, height]);

  // useEffect(() => {
  //   return () => unlistenRef.current();
  // }, []);

  return <canvas className={styles.canvas} ref={canvasRef}></canvas>;
};

export { BrushCanvas };
